import React from "react";
import PropTypes from 'prop-types';

const { Component } = React;

export default class SelectField extends Component {

    static propTypes = {
        onChange: PropTypes.func,
        options: PropTypes.array,
        value: PropTypes.node
    }

    render() {
        return <select className="form-control" value={this.props.value} onChange={(e) => this.props.onChange(e)}>
            {this.props.options && this.props.options.map(option => {
                return <option key={option.val} value={option.val}>{option.label}</option>
            })}
        </select>
    }

}
