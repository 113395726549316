import {Decimal} from 'decimal.js';

export default class Common {

    multiselect_options = {
        numberDisplayed: 0,
        includeSelectAllOption: true,
        allSelectedText: 'alles ausgewählt',
        nonSelectedText: 'bitte wählen',
        selectAllText: 'alles auswählen',
        nSelectedText: 'ausgewählt',
        buttonClass: 'form-control',
        buttonContainer: '<div class="btn-group bootstrap-multiselect" />'
    };

    daterangepicker_locale = {
        format: 'DD.MM.YYYY',
        applyLabel: "wählen",
        cancelLabel: "abbrechen",
        daysOfWeek: [
            "So",
            "Mo",
            "Di",
            "Mi",
            "Do",
            "Fr",
            "Sa"
        ],
        monthNames: [
            "Januar",
            "Februar",
            "März",
            "April",
            "mai",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "Dezember"
        ]
    };

    eurofy (value) {
        if (!value) {
            return '0,00 €'
        }
        if (value || value === 0) {
            value = new Decimal(value);
            value = value.toFixed(2);
            return value.replace('.', ',') + ' €';
        }
        return value;
    }

    init = function () {

    };
};