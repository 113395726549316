import React from "react";
import CalendarForm from "./CalendarForm";
import PropTypes from 'prop-types';

const { Component } = React;

export default class SummaryLegend extends Component {
    static propTypes = {
        orders: PropTypes.array,
        showStatistics: PropTypes.bool,
        orderCount: PropTypes.number,
        onLegendSelect: PropTypes.func
    }

    purchaseStatusCount(status) {
        return this.props.orders.filter(order => order.purchase_status === status).length
    }

    shippingStatusCount(status) {
        return this.props.orders.filter(order => order.shipping_status === status).length
    }

    packageStatusCount(status) {
        if (status === 'not-ready')
            return this.props.orders.filter(order => order.packaging_status === status || !order.packaging_status).length
        return this.props.orders.filter(order => order.packaging_status === status).length
    }

    customTemplateCount() {
        return this.props.orders.filter(order => order.print_custom).length
    }


    render() {
        return <div className='summary-legend'>
            <div className='row justify-content-around'>
                <div className='col clickable-items'>
                    <ul className='list-unstyled'>
                        <li onClick={() => this.props.onLegendSelect('purchase_status', 'inquired')}>
                            <i className="fa fa-shopping-cart inquired"></i> {this.purchaseStatusCount('inquired')} angefragt
                        </li>
                        <li onClick={() => this.props.onLegendSelect('purchase_status', 'confirmed')}>
                            <i className="fa fa-shopping-cart confirmed"></i> {this.purchaseStatusCount('confirmed')} bestätigt
                        </li>
                        <li onClick={() => this.props.onLegendSelect('purchase_status', 'on-hold')}>
                            <i className="fa fa-shopping-cart pending"></i> {this.purchaseStatusCount('on-hold')} gebucht
                        </li>
                        <li onClick={() => this.props.onLegendSelect('purchase_status', 'completed')}>
                            <i className="fa fa-shopping-cart completed"></i> {this.purchaseStatusCount('completed')} bezahlt
                        </li>

                    </ul>
                </div>
                <div className='col clickable-items'>
                    <ul className='list-unstyled'>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'registered')}>
                            <i className="fa fa-truck registered"></i> {this.shippingStatusCount('registered')} Tracking Code vorhanden
                        </li>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'shipped')}>
                            <i className="fa fa-truck shipped"></i> {this.shippingStatusCount('shipped')} verschickt
                        </li>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'at-customer')}>
                            <i className="fa fa-truck at-customer"></i> {this.shippingStatusCount('at-customer')} beim Kunden
                        </li>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'at-customer-delayed')}>
                            <i className="fa fa-truck at-customer-delayed"></i> {this.shippingStatusCount('at-customer-delayed')} beim Kunden überfällig
                        </li>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'returning')}>
                            <i className="fa fa-truck returning"></i> {this.shippingStatusCount('returning')} zurückgeschickt
                        </li>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'returning-soon')}>
                            <i className="fa fa-truck returning-soon"></i> {this.shippingStatusCount('returning-soon')} kommen bald zurück
                        </li>
                        <li onClick={() => this.props.onLegendSelect('shipping_status', 'returned')}>
                            <i className="fa fa-truck returned"></i> {this.shippingStatusCount('returned')} zurück bei Fexobox
                        </li>
                    </ul>
                </div>
                <div className='col'>
                    <ul className='list-unstyled'>
                        <li onClick={() => this.props.onLegendSelect('packaging_status', 'not-ready')}>
                            <i className="fa fa-archive not-ready"></i> {this.packageStatusCount('not-ready')} nicht angefangen
                        </li>
                        <li onClick={() => this.props.onLegendSelect('packaging_status', 'templated')}>
                            <i className="fa fa-archive templated"></i> {this.packageStatusCount('templated')} Template erstellt
                        </li>
                        <li onClick={() => this.props.onLegendSelect('packaging_status', 'labeled')}>
                            <i className="fa fa-archive labeled"></i> {this.packageStatusCount('labeled')} Versand erstellt
                        </li>
                        <li onClick={() => this.props.onLegendSelect('packaging_status', 'ready')}>
                            <i className="fa fa-archive ready"></i> {this.packageStatusCount('ready')} fertig
                        </li>

                    </ul>
                </div>
                <div className='col'>
                    {!!this.props.showStatistics && !!this.props.orderCount &&
                    <ul className='list-unstyled'>
                        <li>{this.customTemplateCount()} / {this.props.orderCount} = {Math.round(this.customTemplateCount() / this.props.orderCount * 100)} % Custom Template</li>
                        <li>{Math.round(this.props.orderCount / 175 * 100)} % ausgebucht</li>
                    </ul>}
                </div>

            </div>
        </div>
    }
}