export default class OrderPreview  {
    init() {
        this.main = $('#order-preview');
        this.width = this.main.width();
        this.zoom = this.width / config.dimensions.width;
        this.ratio = config.dimensions.width / config.dimensions.height;
        this.height = this.width / this.ratio;
        this.main.height(this.height);

        var html = '<img src="' + config.background.url + '" id="op-background" style="z-index: ' + config.background.z_index + '">';
        html += '<img src="' + config.overlay.url + '" id="op-overlay" style="z-index: ' + config.overlay.z_index + '">';
        for (var i = 0; i < config.photo.length; i++) {
            var photo = config.photo[i];
            var styles = [
                'width: ' + photo.width * this.zoom + 'px;',
                'height: ' + photo.height * this.zoom + 'px;',
                'top: ' + photo.top  * this.zoom + 'px;',
                'left: ' + photo.left * this.zoom + 'px;',
                'z-index: ' + photo.z_index + ';',
                'transform: rotate(' + photo.rotation + 'deg);'
            ];
            html += '<div class="op-photo" id="op-photo-' + photo.id + '" style="' + styles.join('') + '"></div>';
        }

        $.each(config.text, function (index, text) {
            console.log(text.width);
            var styles = [
                'width: ' + text.width * modules.order_preview.zoom + 'px;',
                'height: ' + text.height * modules.order_preview.zoom + 'px;',
                'top: ' + text.top  * modules.order_preview.zoom + 'px;',
                'left: ' + text.left * modules.order_preview.zoom + 'px;',
                'z-index: ' + text.z_index + ';',
                'transform: rotate(' + text.rotation + 'deg);',
                'font-family: \'' + text.font_family + '\';',
                'font-size: ' + text.font_size * modules.order_preview.zoom + 'px;',
                'color: rgba(' + parseInt(text.font_color.substr(1, 2), 16) + ',' + parseInt(text.font_color.substr(3, 2), 16) + ',' + parseInt(text.font_color.substr(4, 2), 16) + ',' + parseInt(text.font_color.substr(6, 2), 16) + ');'
            ];
            html += '<div class="op-text" style="' + styles.join('') + '">' + index + '</div>';
        });

        this.main.append(html);

    };
};