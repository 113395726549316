export default class OrderSearch{
    init() {
        $('a.pagination-page').click(function(evt) {
            evt.preventDefault();
            var list_table = $('.common-list-table');
            $('#page').val($(this).attr('data-page'));
            $('#submit').trigger('click');
        });
        this.old_order_id = $('#order_id').val();
        this.old_name = $('#name').val();
        $('#order-search-form').submit(function () {
            modules.order_search.page_check();
        });
    };

    page_check = function () {
        var new_order_id = $('#order_id').val();
        var new_name = $('#name').val();

        if (new_order_id !== this.old_order_id || new_name !== this.old_name) {
            $('#page').val('1');
        }
    };
};