
import '../sass/base.scss'
import '../sass/webapp.scss'

import React from "react";
import ReactDOM from "react-dom";

import Common from './Common';
import OrderPreview from "./OrderPreview";
import OrderSearch from "./OrderSearch";
import OrderDetails from "./OrderDetails";
import Calendar from "./Calendar";
import Order from "./Order";
import HistoryChart from "./charts/HistoryChart";
import PreOrderTimeChart from "./charts/PreOrderTimeChart";
import CalendarReact from "./calendar/CalendarReact";
import SearchTableTemplate from './SearchTable/SearchTableTemplate';


window.modules = {};

$(document).ready(function () {
    modules.common = new Common();
    modules.common.init();
    window.common = modules.common;
    if ($('#order-preview').length) {
        modules.order_preview = new OrderPreview();
        modules.order_preview.init();
    }
    if ($('#order-search-form').length) {
        modules.order_search = new OrderSearch();
        modules.order_search.init();
    }
    if ($('#order-status-form').length) {
        modules.order_details = new OrderDetails();
        modules.order_details.init();
    }
    if ($('#calendar-form').length) {
        modules.calendar = new Calendar();
        modules.calendar.init();
    }
    if ($('#order-form').length) {
        modules.order = new Order();
        modules.order.init();
    }
    if ($('#order-history-chart-container').length) {
        ReactDOM.render(
            <HistoryChart ref={(el) => {modules.historyChart = el}} />,
            document.getElementById("order-history-chart-container")
        );
    }
    if ($('#preorder-time-chart-container').length) {
        ReactDOM.render(
            <PreOrderTimeChart ref={(el) => {modules.preOrderTimeChart = el}} />,
            document.getElementById("preorder-time-chart-container")
        );
    }

    if ($('#calendar-container').length) {
        ReactDOM.render(
            <CalendarReact ref={(el) => {modules.calendarReact = el}}/>,
            document.getElementById('calendar-container')
        )
    }

    if (document.getElementById('order-search-results')) {
        ReactDOM.render(
            <SearchTableTemplate ref={(searchTableTemplate) => {window.searchTableTemplate = searchTableTemplate}} />,
            document.getElementById("order-search-results")
        );
    }

    $('.multiselectify').each(function(el) {
            $(this).multiselect(modules.common.multiselect_options)
        }
    )
});