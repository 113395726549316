import React from "react";
import PropTypes from 'prop-types';
import SelectField from "./SelectField";

const { Component } = React;

export default class IdSelection extends Component{

    static propTypes = {
        onChange: PropTypes.func
    }

    state = {
        id: '',
        source: 'all'
    }

    emitState(e) {
        if (e) {
            e.preventDefault()
        }
        this.props.onChange(this.state)
    }

    render() {
        return <form onSubmit={(e) => this.emitState(e)}>
        <div className="row row-form">

                <div className="col-md-7">
                    <label htmlFor="order_id">ID</label>
                    <input className='form-control' value={this.state.id} onChange={(e) => this.setState({id: e.target.value})}/>
                </div>
                <div className="col-md-2">
                    <label htmlFor="source">Quelle</label>
                    <SelectField id="source" value={this.state.source} options={[{label: 'alles', val: 'all'}, {label: 'DE', val: 'de'}, {label: 'AT', val: 'at'}, {label: 'PRO', val: 'pro'}]} onChange={(e) => this.setState({source: e.target.value})}/>
                </div>
                <div className="col-md-3">
                    <label>&nbsp;</label>
                    <input className="form-control" id="submit" name="submit" type="submit" value="wählen"/>
                </div>
        </div>
                        </form>

    }
}