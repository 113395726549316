import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableTemplate extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    apiUrl = '/api/templates';
    formId = 'template-search-form';
    varPrefix = 'searchTableTemplate';
    loadParamsRegExp = [
        /\/template\/(.*)/g
    ];

    sortDef = [
        { key: 'name', name: 'Name' }
    ];

    colDef = [
        { text: 'Template' },
        { text: 'Name'},
        { text: 'Aktionen' }
    ];

    renderTableRow(row) {
        return (
            <tr>
                {this.renderTableCellImage(row)}
                {this.renderTableCellName(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellImage(row) {
        return(
            <td>
                <img src={`/static/images/template/overlay/${row.id}.thumb.png`} alt={`${row.name}`}/>
            </td>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td className="nobreak">
                {this.renderActionLink(`/template/${row.id}/show`, 'info', true)}
                {this.renderActionLink(`/template/${row.id}/edit`, 'edit', true)}
                {this.renderActionLink(`/template/${row.id}/delete`, 'delete', true)}
            </td>
        )
    }

}