import React from "react";
import PropTypes from 'prop-types';

const { Component } = React;

export default class CsvGenerator extends Component {

    static propTypes = {
        orders: PropTypes.array,
        onReset: PropTypes.func
    }

    state = {
        shipping_type: 'dhl'
    }

    onSubmit(e) {
        e.preventDefault()
        var data = {
                order_ids: this.props.orders.join(', '),
                shipping_type: this.state.shipping_type
            };
            const w = window.open('/delivery/shipping-csv?data=' + JSON.stringify(data));
            w.focus();
            w.onblur = function() {w.close()};
        this.props.onReset()
    }


    render() {
        return <div>
            <div className='row'>
                <div className='col-6'>
                    <label>Bestell-IDs</label>
                    <span className='form-control' style={{border: 'none'}}>
                        {this.props.orders.map(orderId => {
                            return <span key={orderId} className=" ml-1 badge badge-pill badge-dark">{orderId}</span>
                        })}
                    </span>
                </div>
                <div className='col-2'>
                    <label>Versand-Type</label>
                    <select className="form-control" value={this.state.shipping_type}
                            onChange={(e) => this.setState({shipping_type: e.target.value})}>
                        <option value="dhl">DHL</option>
                        <option value="dpd">DPD</option>
                    </select>
                </div>
                <div className='col-4'>
                    <label>&nbsp;</label>
                    <input className="form-control" id="csv_submit" name="csv_submit" type="submit"
                           value="CSV der markierten Pakete generieren" disabled={!this.props.orders.length} onClick={(e) => this.onSubmit(e)}/>

                </div>
            </div>
        </div>
    }
}