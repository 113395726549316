import React from "react";
import SelectField from "./SelectField";
import PropTypes from 'prop-types';

const { Component } = React;

const MIN_KW = 1
const MAX_KW = 52

export default class CalendarForm extends Component {

    static propTypes = {
        onChange: PropTypes.func
    }

    state = {
        options: null,
        source: 'all',
        week: moment().isoWeek(),
        year: moment().year(),
        purchase_status: 'all',
        shipping_status: 'all'
    }

    getWeekOptions() {
        const result = []
        for (let i = MIN_KW; i <= MAX_KW; i++) {
            result.push({label: `KW ${i}`, val: i})
        }
        return result
    }

    componentDidMount() {
        this.emitState(this.state)
        $.getJSON('/api/calendar/select-options', (result) => {
            this.setState({options: result})
        })
    }

    incrementWeek() {
        let newState
        if (this.state.week === MAX_KW) {
            newState = {year: this.state.year + 1, week: MIN_KW}
        } else {
            newState = {week: this.state.week + 1}
        }
        this.setState(newState)
        this.emitState(newState)
    }

    decrementWeek() {
        let newState
        if (this.state.week === MIN_KW) {
            newState = {year: this.state.year - 1, week: MAX_KW}
        } else {
            newState = {week: this.state.week - 1}
        }
        this.setState(newState)
        this.emitState(newState)
    }

    valueChange(e, field) {
        this.setValue(e.target.value, field);
    }

    setValue(val, field) {
        const newState = {[field]: (this.state[field] === val) ? undefined : val}
        this.setState(newState)
        this.emitState(newState)
    }

    emitState(state = {}) {
        this.props.onChange && this.props.onChange(Object.assign({}, this.state, state))
    }

    render() {
        if (!this.state.options) {
            return <div/>
        }
        return <div>
            <div className="row row-form">
                <div className="col-md-4">
                    <label htmlFor="source">Quelle</label>
                    <SelectField id="source" value={this.state.source} options={this.state.options.source} onChange={(e) => this.valueChange(e, 'source')}/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="week">Woche</label>
                    <SelectField id="week" value={this.state.week} options={this.state.options.week} onChange={(e) => this.valueChange(e, 'week')}/>
                </div>
                <div className="col-md-4">
                    <label htmlFor="year">Jahr</label>
                    <input className="form-control" id="year" type="text" value={this.state.year} onChange={(e) => this.valueChange(e, 'year')}/>
                </div>
            </div>
            <div className="row row-form">
                <div className="col-md-4">
                    <label htmlFor="purchase_status">Kauf-Status</label>
                    <SelectField id="purchase_status" value={this.state.purchase_status} options={this.state.options.purchase_status} onChange={(e) => this.valueChange(e, 'purchase_status')}/>
                </div>
                <div className="col-md-4">

                    <label htmlFor="shipping_status">Versand-Status</label>
                    <SelectField id="shipping_status" value={this.state.shipping_status} options={this.state.options.shipping_status} onChange={(e) => this.valueChange(e, 'shipping_status')}/>
                </div>
                <div className="col-md-4">
                    <label>&nbsp;</label>
                    <input className="form-control" id="submit" name="submit" type="submit" value="wählen" onClick={() => this.emitState(this.state)}/>
                </div>
            </div>
        </div>
    }

}
