export default class Calendar {

    init() {
        $('#order_ids').val('');
        //Adds all to the csv selection
        $('.csv-head-select').click(function () {
            $('.cal-order-day-' + $(this).data('day') + ' .fa-archive.templated').click();
        });
        $('.fa-archive').click(function () {
            //move status to templated
            if ($(this).hasClass('not-ready')) {
                $.post('/api/order/' + $(this).data('id') + '/packaging-status', { packaging_status: 'templated' }, function (data) {
                    if (data.status === 0) {
                        var i = $('.fa-archive[data-id="' + data.id + '"]').addClass('templated').removeClass('not-ready');
                    }
                });
            }
            //toggle between templated and pre lablled, adjust csv input field
            else if ($(this).hasClass('templated')) {
                if (!$(this).hasClass('completed'))
                    return;
                var ids = $('#order_ids').val().split(', ');
                if (ids.length)
                    if (!ids[0])
                        ids.splice(0, 1);
                ids.push(String($(this).data('uid')));
                $('#order_ids').val(ids.join(', '));
                $(this).removeClass('templated').addClass('pre-labeled');
            }
            //toggle between templated and pre labled, adjust csv input field
            else if ($(this).hasClass('pre-labeled')) {
                var ids = $('#order_ids').val().split(', ');
                var index = ids.indexOf(String($(this).data('uid')));
                if (index > -1)
                    ids.splice(index, 1);
                $('#order_ids').val(ids.join(', '));
                $(this).addClass('templated').removeClass('pre-labeled');
            }
            //move status to ready
            else if ($(this).hasClass('labeled')) {
                $.post('/api/order/' + $(this).data('id') + '/packaging-status', { packaging_status: 'ready' }, function (data) {
                    if (data.status === 0) {
                        var i = $('.fa-archive[data-id="' + data.id + '"]').addClass('ready').removeClass('labeled');
                        var p = i.parent().parent().parent().find('span');
                        p.text(parseInt(p.text()) - 1);
                    }
                });
            }
            /*else if ($(this).hasClass('ready')) {
                if (confirm('Paket-Status wirklich wieder zurücksetzen?')) {
                    $.post('/api/order/' + $(this).data('id') + '/packaging-status', { packaging_status: 'labeled' }, function (data) {
                        if (data.status === 0) {
                            var i = $('.fa-archive[data-id="' + data.id + '"]').removeClass('ready').addClass('labeled');
                            var p = i.parent().parent().parent().find('span');
                            p.text(parseInt(p.text()) + 1);
                        }
                    });
                }
            }*/
        });
        //submits search form with select list values according to the clicked icon
        $('.form-icon-select').click(function () {
            $('#' + $(this).data('field')).val($(this).data('value'));
            $('#submit').click();
        });

        //submit csv form
        $('#shipping-csv-form').submit(function(evt) {
            evt.preventDefault();
            var data = {
                order_ids: $('#order_ids').val(),
                shipping_type: $('#shipping_type').val(),
                csrf_token: $('#csrf_token').val()
            };
            window.open('/delivery/shipping-csv?data=' + JSON.stringify(data));
            window.setTimeout(function() {
                location.reload();
            }, 50);
        });
    };
};